import Button, { BtnStyles } from 'components/elements/buttons/button'
import * as href from 'lib/constants/hrefs'
import Link from 'next/link'
import { Button as ButtonSchema } from 'types/strapi/item'

const ButtonSchemaToButtonComponent = (buttonSchema: ButtonSchema) => {
  const stringToOnClick = (str: string) => {
    switch (str) {
      case 'Application':
        return `${href.MY_TELLA}/application`
      case 'Home':
        return '/'
      case 'ContactUs':
        return '/contact-us'
      case 'KiwiSaverProviders':
        return '/kiwisaver-providers'
      default:
        alert('url missing!')
    }
  }

  return (
    <Link
      href={`${
        buttonSchema.externalUrl
          ? buttonSchema.externalUrl
          : buttonSchema.predefinedUrls
          ? stringToOnClick(buttonSchema.predefinedUrls)
          : buttonSchema.websitePage.data.attributes.urlSlug
      }`}
      passHref
    >
      <a>
        <Button text={buttonSchema.title} style={buttonSchema.color as BtnStyles} width="w-max px-6" />
      </a>
    </Link>
  )
}

export default ButtonSchemaToButtonComponent
