import { SVGProps } from 'react'

const IcDiagonalArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 300 300"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>{'icn-arrow-diagonal-blue-fIN'}</title>
    <g id="icn-arrow-diagonal-blue-fIN" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="icn-arrow-down-outline" transform="translate(4.000000, 4.000000)" fill="#10069F" fillRule="nonzero">
        <path
          d="M274.124335,292.059586 C269.388906,292.036019 264.852304,290.152902 261.492198,286.816057 L4.7976469,30.0619203 C-1.7653168,23.0186757 -1.57166413,12.0430181 5.235677,5.235677 C12.0430181,-1.57166413 23.0186757,-1.7653168 30.0619203,4.7976469 L286.756472,261.551784 C291.862245,266.66391 293.388539,274.346996 290.624455,281.022519 C287.860372,287.698041 281.34948,292.053231 274.124335,292.059586 Z"
          id="Path"
        />
        <path
          d="M274.124335,292.059586 L61.8806042,292.059586 C52.008147,292.059586 44.0049391,284.056378 44.0049391,274.18392 C44.0049391,264.311463 52.008147,256.308255 61.8806042,256.308255 L256.24867,256.308255 L256.24867,63.4894141 C256.24867,53.6169568 264.251878,45.613749 274.124335,45.613749 C283.996792,45.613749 292,53.6169568 292,63.4894141 L292,274.18392 C292,284.056378 283.996792,292.059586 274.124335,292.059586 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
)

export default IcDiagonalArrow
