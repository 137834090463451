import Container from 'components/elements/containers/container'
import Image from 'next/image'
import { ReactNode } from 'react'

type Props = {
  imgSrc: StaticImageData
  imgSrcTwo: StaticImageData
  children: ReactNode
}

const HeroWithBgImage = ({ imgSrc, imgSrcTwo, children }: Props) => {
  return (
    <>
      <div className="absolute -z-10 mt-12 hidden h-[600px] w-full sm:h-[648px] md:inline">
        <Image
          alt="banner"
          src={imgSrc}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          quality={100}
          priority={true}
        />
      </div>
      <div className="absolute -z-10 h-[1000px] w-full sm:h-[1200px] md:mt-12 md:hidden md:h-[648px]">
        <Image
          alt="banner"
          src={imgSrcTwo}
          layout="fill"
          objectFit="cover"
          objectPosition="bottom"
          quality={100}
          priority={true}
        />
      </div>
      <Container height="h-[900px] sm:h-[1200px] md:h-[648px]">{children}</Container>
    </>
  )
}

export default HeroWithBgImage
