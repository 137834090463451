import 'react-multi-carousel/lib/styles.css'

import TextWithImageButton from 'components/content-blocks/text-with-image-button'
import Button from 'components/elements/buttons/button'
import ReviewCard from 'components/elements/cards/review-card'
import Container from 'components/elements/containers/container'
import IcDiagonalArrow from 'components/elements/images/ic-diagonal-arrow'
import ImgCatSleeping from 'components/elements/images/img-cat-sleeping'
import ImgCatTired from 'components/elements/images/img-cat-tired'
import HeroWithBgImage from 'components/layouts/hero-section/hero-with-bg-image'
import { HeroColorAndGlossaryLetterContext } from 'components/layouts/layout'
import BankLogosCarousel from 'components/pages/home/bank-logos-carousel'
import LadyAndSofa from 'images/home-page/lady-and-sofa.png'
import LeafyPlant from 'images/others/leafy-plant.png'
import BenGoldsmith from 'images/team-members/ben-goldsmith.png'
import KavinMurthy from 'images/team-members/kavin-murthy.png'
import { fetchStrapiAPI } from 'lib/api'
import { EV_BOOK_A_CHAT, EV_HL_APPLICATION_PATH_CLICK } from 'lib/constants/analytics-events'
import * as href from 'lib/constants/hrefs'
import { stringToImgComponent } from 'lib/switch-fns/string-to-img-component'
import StyleToLinkComponent from 'lib/switch-fns/style-to-link-component'
import { fireGAEvent } from 'lib/utilities'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useContext, useEffect, useState } from 'react'
import { PopupModal } from 'react-calendly'
import {
  isContentBlockLinks,
  isContentBlockReviews,
  isContentBlockText,
  isContentBlockTextAndImage,
  isContentBlockTextsWithImage,
  isContentBlockTextWithImageButton,
  isContentBlockUniversals,
} from 'types/strapi/content-blocks'
import { Home } from 'types/strapi/home'

import * as Sentry from '@sentry/react'

import CanStar from '../../public/canstart-logo.png'
import heroBgMoblie from '../../public/home-page-hero-image-mobile.png'
import heroBg from '../../public/home-page-hero-image.png'

// import heroBgNight from '../../public/home-hero-bg-night.png'

import type { GetStaticProps } from 'next'
type Props = {
  home: Home
}

const Home = ({ home }: Props) => {
  // const [isDayTime, setIsDayTime] = useState(true)
  const [storedCampaignString] = useState(typeof window !== 'undefined' ? localStorage.queryString : '')
  const { contentBlocks, seo } = home.attributes
  const { setHeroBgColor } = useContext(HeroColorAndGlossaryLetterContext)
  useEffect(() => {
    setHeroBgColor('')
  })
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false)

  //temp solution until strapi upload images get fixed
  // const charityLogos = importAll(require.context('/src/images/charities-logos', false, /\.(png|jpe?g|svg)$/))

  // Disabled and keep it for now in case we need it in the future
  // const hours = new Date().getHours()
  // if (hours < 6 || hours > 19) {
  //   setIsDayTime(false)
  // }
  //eslint-disable-line

  return (
    <>
      <Head>
        <title>{seo?.metaTitle || 'Tella - Home Loans'}</title>
        <meta name="description" content={seo?.metaDescription || home.attributes?.title} />
        {seo?.keywords && <meta name="keywords" content={seo.keywords} />}
      </Head>
      <HeroWithBgImage imgSrc={heroBg} imgSrcTwo={heroBgMoblie}>
        <div className="relative bottom-36 mx-auto mt-10 flex h-full justify-start sm:bottom-72 sm:justify-center md:bottom-0 md:w-10/12 md:justify-start">
          <div className="my-auto grid h-min w-full gap-4 sm:w-96">
            <h1 className="font-semibold">{home.attributes?.title}</h1>
            <h2 className="text-xl">{home.attributes?.intro}</h2>
            <div className="relative">
              <Image src={CanStar} alt="" />
            </div>
            <div id="calendly" className="mt-2 flex space-x-3">
              <Link href={'#'} passHref>
                <a className="w-full" id="calendly">
                  <Button
                    style="Green"
                    text="Book a chat"
                    width="w-full sm:w-40"
                    onClick={() => (fireGAEvent({ event: EV_BOOK_A_CHAT }), setIsCalendlyOpen(true))}
                  />
                  {typeof window !== 'undefined' && (
                    <PopupModal
                      url={href.BOOK_A_CHAT}
                      open={isCalendlyOpen}
                      onModalClose={() => {
                        setIsCalendlyOpen(false)
                      }}
                      rootElement={document.getElementById('calendly') as HTMLElement}
                    />
                  )}
                </a>
              </Link>
              <Link href={href.HL_APPLICATION} passHref>
                <a className="w-full" target="_blank">
                  <Button
                    style="Green"
                    text="Apply online"
                    width="w-full sm:w-40"
                    onClick={() =>
                      fireGAEvent({ event: EV_HL_APPLICATION_PATH_CLICK, element: 'Apply online (Home_Hero)' })
                    }
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </HeroWithBgImage>
      <BankLogosCarousel />
      {contentBlocks.map((contentBlock, index) => (
        <div key={index}>
          {isContentBlockTextAndImage(contentBlock) && contentBlock.type === 'promotion' && (
            <Container bgColor="bg-tella-light-pink-100">
              <div className="mx-auto mb-10 mt-10 grid w-10/12 grid-cols-1 gap-10 md:mb-0 md:mt-12 md:grid-cols-2 md:pr-10">
                <div>
                  <p className="text-lg text-[#37C49F]">{contentBlock.colorTitle}</p>
                  <p className="mb-5 text-3xl font-semibold lg:text-4xl">{contentBlock.heading}</p>
                  <p
                    className="mb-5"
                    dangerouslySetInnerHTML={{ __html: contentBlock.intro.replace(/\n/g, '<br/>') }}
                  />
                  <div className="mt-14 grid gap-2 md:flex">
                    <Link href={'#'} passHref>
                      <a className="w-full" id="calendly">
                        <Button
                          style="Green"
                          text="Book a chat"
                          width="w-full"
                          onClick={() => (fireGAEvent({ event: EV_BOOK_A_CHAT }), setIsCalendlyOpen(true))}
                        />
                        {typeof window !== 'undefined' && (
                          <PopupModal
                            url={href.BOOK_A_CHAT}
                            open={isCalendlyOpen}
                            onModalClose={() => {
                              setIsCalendlyOpen(false)
                            }}
                            rootElement={document.getElementById('calendly') as HTMLElement}
                          />
                        )}
                      </a>
                    </Link>
                    <Link href={href.HL_APPLICATION} passHref>
                      <a className="w-full" target="_blank">
                        <Button
                          style="Green"
                          text="Apply online"
                          width="w-full"
                          onClick={() => fireGAEvent({ event: EV_HL_APPLICATION_PATH_CLICK, element: 'Apply online' })}
                        />
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="relative right-3 w-full sm:right-6 md:right-0 md:top-24 md:w-[350px] lg:-top-10  lg:w-[520px]">
                  <Image
                    objectFit="cover"
                    objectPosition="center"
                    src={contentBlock.sectionImage?.data.attributes.url ?? LadyAndSofa}
                    height={100}
                    width={100}
                    alt="Get Started with Tella"
                    quality={100}
                    layout="responsive"
                  />
                </div>
              </div>
            </Container>
          )}
          {/* Review Section */}
          {isContentBlockReviews(contentBlock) &&
            contentBlock.reviews &&
            contentBlock.reviews.length > 0 &&
            contentBlock.type === 'white-background-review' && (
              <Container bgColor="bg-white">
                <div className="mx-auto mb-10 flex w-10/12 flex-col">
                  <div className="my-12 flex flex-col sm:w-1/3 md:w-1/2">
                    <p className="flex items-start text-xl font-semibold">{contentBlock.heading}</p>
                  </div>
                  {/* Review component */}
                  <div className="grid items-start gap-10 sm:grid-cols-1 md:grid-cols-2">
                    {/* only displaus 6 ReviewCard in this page */}
                    {contentBlock.reviews.slice(0, 4).map((review, index) => (
                      <div key={index}>
                        <ReviewCard
                          key={index}
                          title={review.title}
                          description={review.comment}
                          reviewer={review.reviewer}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Container>
            )}
          {isContentBlockReviews(contentBlock) &&
            contentBlock.reviews &&
            contentBlock.reviews.length > 0 &&
            contentBlock.type === 'colorful-background-review' && (
              <Container bgColor="bg-tella-blue-300" extraClasses="relative">
                {/* Colorful background review component */}
                <div className="mx-auto flex w-10/12 flex-col">
                  <div className="grid items-start gap-10 py-16 sm:grid-cols-1 md:grid-cols-2">
                    {/* only displaus 6 ReviewCard in this page */}
                    {contentBlock.reviews.slice(0, 4).map((review, index) => (
                      <div key={index} className="z-10">
                        <ReviewCard
                          key={index}
                          title={review.title}
                          description={review.comment}
                          reviewer={review.reviewer}
                          reviewCardColor={'bg-[#4038B1] text-white'}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="absolute -top-12 right-10 hidden sm:block">
                    <Image height={300} width={300} src={LeafyPlant} alt="" />
                  </div>
                </div>
              </Container>
            )}
          {isContentBlockUniversals(contentBlock) && (
            <Container bgColor="bg-tella-light-pink-100">
              <div className="mx-auto flex w-10/12 flex-col md:flex-row md:justify-between">
                <div className="flex flex-col md:w-1/3">
                  <h2 className="flex items-start font-semibold">{contentBlock.heading}</h2>
                  <p className="my-5">{contentBlock.headIntro}</p>
                  <div className="mb-8 grid gap-4">
                    <Link href={'#'} passHref>
                      <a className="w-full" id="calendly">
                        <Button
                          style="Green"
                          text="Book a chat"
                          width="w-full"
                          onClick={() => (fireGAEvent({ event: EV_BOOK_A_CHAT }), setIsCalendlyOpen(true))}
                        />
                        {typeof window !== 'undefined' && (
                          <PopupModal
                            url={href.BOOK_A_CHAT}
                            open={isCalendlyOpen}
                            onModalClose={() => {
                              setIsCalendlyOpen(false)
                            }}
                            rootElement={document.getElementById('calendly') as HTMLElement}
                          />
                        )}
                      </a>
                    </Link>
                    <Link href={href.HL_APPLICATION} passHref>
                      <a target="_blank">
                        <Button
                          style="Green"
                          text="Apply online"
                          containerClass="w-2/3"
                          onClick={() =>
                            fireGAEvent({ event: EV_HL_APPLICATION_PATH_CLICK, element: 'Apply online (Home_Body)' })
                          }
                        />
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="flex h-full flex-col items-start md:w-1/2">
                  {contentBlock.universals.map((universal, index) => (
                    <div
                      key={index}
                      className={`my-2 w-full border-tella-blue-300 pl-2 md:w-10/12  ${
                        index !== 3 ? 'border-b-2' : ''
                      }`}
                    >
                      <h3 className="font-semibold">{universal.title}</h3>
                      <p className="mb-4">{universal.intro}</p>
                    </div>
                  ))}
                  <div className="grid grid-flow-col gap-2">
                    <Image
                      height={70}
                      width={70}
                      src={BenGoldsmith}
                      alt="Ben"
                      layout="intrinsic"
                      objectFit="cover"
                      className="rounded-full"
                      quality="100"
                    />
                    <Image
                      height={70}
                      width={70}
                      src={KavinMurthy}
                      alt="Kavin"
                      layout="intrinsic"
                      objectFit="cover"
                      className="rounded-full"
                      quality="100"
                    />
                  </div>
                </div>
              </div>
            </Container>
          )}
          {isContentBlockLinks(contentBlock) && (
            <>
              {contentBlock.style === 'LinkRectImageTitleStandard' ? (
                <Container bgColor="bg-tella-light-pink-100">
                  <div className="mx-auto flex w-10/12 flex-col">
                    <h2 className="font-semibold">{contentBlock.heading}</h2>
                    <div className="mx-auto my-8 grid gap-6 sm:grid-cols-2 xl:grid-cols-4">
                      {contentBlock.links.map((link, index) => (
                        <StyleToLinkComponent
                          link={link}
                          key={index}
                          style={contentBlock.style}
                          queryString={storedCampaignString}
                        />
                      ))}
                    </div>
                  </div>
                </Container>
              ) : contentBlock.style === 'LinkRectTitleIntroArrow' ? (
                <Container bgColor="bg-white relative">
                  <div className="absolute -bottom-[40px] right-1/4">
                    <div className="relative h-24 w-40">
                      <ImgCatSleeping />
                    </div>
                  </div>
                  <div className="mx-auto mb-20 w-10/12">
                    <h2 className="w-full font-semibold">{contentBlock.heading}</h2>
                    <div className="flex sm:ml-auto md:w-3/4">
                      <div className="hidden md:block">
                        <IcDiagonalArrow />
                      </div>
                      <div className="mt-10 w-full">
                        {contentBlock.links.map((link, index) => (
                          <StyleToLinkComponent
                            link={link}
                            key={index}
                            style={contentBlock.style}
                            queryString={storedCampaignString}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </Container>
              ) : contentBlock.style === 'LinkRectImageTitleIntroBorderTop' ? (
                <Container bgColor="bg-tella-light-pink-100">
                  <div className="mx-auto my-14 w-10/12 md:my-20">
                    <h2 className="mx-auto mb-12 font-semibold sm:mx-0">{contentBlock.heading}</h2>
                    <div className="mx-auto grid w-max gap-8 md:grid-cols-2 lg:grid-cols-3">
                      {contentBlock.links.map((link, index) => (
                        <StyleToLinkComponent
                          link={link}
                          key={index}
                          style={contentBlock.style}
                          queryString={storedCampaignString}
                        />
                      ))}
                    </div>
                  </div>
                </Container>
              ) : (
                <></>
              )}
            </>
          )}
          {isContentBlockTextsWithImage(contentBlock) && (
            <Container bgColor="bg-white">
              <div className="mx-auto flex flex-col sm:w-10/12">
                <h2 className="mx-auto mb-12 text-4xl font-semibold text-tella-green-300 sm:mx-0">
                  {contentBlock.heading}
                </h2>

                {contentBlock.textsWithImage.map((textWithImage, index) => (
                  <div
                    className={`flex flex-col sm:h-96 sm:flex-row ${index % 2 ? 'sm:flex-row-reverse' : ''}`}
                    key={index}
                  >
                    <div className="flex flex-col justify-center pt-6 sm:h-96 sm:w-1/2" key={index}>
                      <p className="flex items-start text-4xl font-semibold">{textWithImage.title}</p>
                      <div className="mt-4 flex text-lg">{textWithImage.intro}</div>
                    </div>
                    <div className="relative flex h-full items-center justify-center sm:w-1/2 sm:scale-125">
                      {stringToImgComponent(textWithImage.image)}
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          )}
          {isContentBlockText(contentBlock) && (
            <Container bgColor="bg-tella-blue-300" extraClasses="relative">
              <div className="absolute -bottom-[85px] left-10">
                <div className="relative h-40 w-44">
                  <ImgCatTired />
                </div>
              </div>
              <div className="relative mx-auto flex w-10/12 flex-col items-end justify-between sm:my-10 sm:flex-row">
                <p className="text-2xl font-semibold text-white sm:text-3xl md:w-1/2">{contentBlock.text}</p>
                <p className="mt-4 italic">{contentBlock.heading}</p>
              </div>
              <div className="absolute -top-12 right-10 hidden sm:block">
                <Image height={300} width={300} src={LeafyPlant} alt="" />
              </div>
            </Container>
          )}
          {/* Partner section - for future use */}
          {/* {index === contentBlocks.length - 3 && (
            <Container bgColor="bg-white">
              <div className="-mb-24 mt-6 grid gap-6">
                <div className="mx-auto w-10/12">
                  <div className="grid gap-2 md:w-1/2">
                    <h2 className="font-semibold">Looking after our backyard</h2>
                    <p>
                      Tella strives to give. Whether its giving time and knowledge back to our customers or looking
                      after our backyard and the people who live in it, our goal is to put people, the planet and profit
                      on an equal playing field.
                    </p>
                  </div>
                </div>
                <div className="mx-auto grid w-max grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {charityLogos.slice(0, 6).map((image, index) => (
                    <div
                      className={`img-wrapper relative flex h-36 w-72 justify-center rounded-xl bg-tella-blue-100 p-4`}
                      key={index}
                    >
                      <Image src={image} layout="intrinsic" alt="logo" objectFit="contain" />
                    </div>
                  ))}
                </div>
              </div>
            </Container>
          )} */}
          {isContentBlockTextWithImageButton(contentBlock) && (
            <Container bgColor="bg-tella-light-pink-100">
              <TextWithImageButton
                textWithImage={contentBlock.textWithImage}
                button={contentBlock.button}
                switchContentPlacement={contentBlock.switchContentPlacement}
              />
            </Container>
          )}
        </div>
      ))}
    </>
  )
}

export default Home

export const getStaticProps: GetStaticProps = async () => {
  try {
    const home = await fetchStrapiAPI<Home>(
      '/home?populate[0]=contentBlocks.keyFacts&populate[1]=contentBlocks.links.guideCategory&populate[2]=contentBlocks.video&populate[3]=seo&populate[4]=contentBlocks.images&populate[5]=contentBlocks.textsWithImage&populate[6]=contentBlocks.links.websitePage&populate[7]=contentBlocks.textWithImage&populate[8]=contentBlocks.button.websitePage&populate[9]=contentBlocks.universals&populate[10]=contentBlocks.reviews&populate[11]=contentBlocks.mortgageSubComponent&populate[12]=contentBlocks.sectionImage'
    )
    return { props: { home: home }, revalidate: parseInt(process.env.NEXT_REVALIDATE || '600') }
  } catch (error) {
    if (process.env.NODE_ENV === 'development') console.log(error)
    else Sentry.captureException(error)
  }
  return {
    notFound: true,
    fallback: true,
  }
}
