import { SVGProps } from 'react'

const ImgCatTired = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 265.775 148.625" {...props}>
      <g id="sleeping-cat" transform="translate(251) rotate(180)">
        <g id="Group-20">
          <path
            id="Path-29"
            d="M205.745-65.37q-21.935-5.014-23.4-2.507H36.122Q52.207,3.572,135.556-.188c83.349-3.761,76.038-10.028,84.811-18.8s7.312-11.281,7.312-11.281c-1.463,0,1.462-10.028,0-10.028s2.924,5.014,2.924-7.521S227.679-60.356,205.745-65.37Z"
            fill="#4038b2"
            fillRule="evenodd"
          />
          <path
            id="Path-30"
            d="M190.385-54.85c5.321-17.885,2.839-52.553-1.166-61.532s-20.306-12.157-21.826-7.047-1.659,11.188,5.386,9.946,4.275,58.562.2,62.912S185.064-36.965,190.385-54.85Z"
            fill="#4038b2"
            fillRule="evenodd"
          />
          <path
            id="Path-30-Copy"
            d="M82.914-63.352c5.321-17.886-3.306-49.5-7.311-58.479s-8.774-11.7-11.7-13.158S44.9-130.6,58.056-124.755s1.462,58.479.865,65.412S77.593-45.467,82.914-63.352Z"
            fill="#4038b2"
            fillRule="evenodd"
          />
          <path
            id="Path-31"
            d="M58-38C35-62,28-65,31-85s19-41,5-50q-14-9-36,9"
            fill="none"
            stroke="#4038b2"
            strokeLinecap="round"
            strokeWidth="21"
            fillRule="evenodd"
          />
          <path
            id="Path-32"
            d="M204.93-7.917c6.575-1.381,13.1-3.367,19.724-4.143,53.957-6.322-16.446-3.889,13.023-39.161,3.206-3.836,10.225-3.7,13.108-7.739,1.288-1.806-3.554-2.338-5.691-2.664-17.484-2.672-35.258-4.193-52.886-6.289"
            fill="#4038b2"
            fillRule="evenodd"
          />
          <path
            id="Path-53"
            d="M171.1-114.312l-1.3,1.558"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
            fillRule="evenodd"
          />
        </g>
        <g id="Group-12" transform="translate(200.008 -21)">
          <path
            id="Path-33"
            d="M10.787-.221C5.906-8.9,6.529-4.325,8.774-13.911"
            transform="translate(-1.878 -3.482) rotate(20)"
            fill="none"
            stroke="#ef426f"
            strokeWidth="1"
            fillRule="evenodd"
          />
          <path
            id="Path-34"
            d="M18.788-24.112C16.1-34.4,14.964-30.256,18.87-36.966"
            transform="translate(-9.383 -7.863) rotate(20)"
            fill="none"
            stroke="#ef426f"
            strokeWidth="1"
            fillRule="evenodd"
          />
          <path
            id="Path-35"
            d="M2.392-19.826c.7-.979,3.23-3.368,2.1-2.937l-2.53-2.986c-1.418.542-1.43,6.432-.013,5.888"
            transform="translate(-7.622 -2.345) rotate(20)"
            fill="#ef426f"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}

export default ImgCatTired
