import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Anz from 'images/bank-logos-blue/logo-anz.png'
import Asb from 'images/bank-logos-blue/logo-asb.png'
import Bnz from 'images/bank-logos-blue/logo-bnz.png'
import CoOpBank from 'images/bank-logos-blue/logo-co-operative-bank.png'
import Kiwibank from 'images/bank-logos-blue/logo-kiwibank.png'
import Sbs from 'images/bank-logos-blue/logo-sbs.png'
import Westpac from 'images/bank-logos-blue/logo-westpac.png'
import Image from 'next/image'
import { ReactNode } from 'react'
import Slider from 'react-slick'

const BankLogosCarousel = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  return (
    <div className="bg-white">
      <div className="py-5 text-center text-lg text-tella-blue-300">
        We’ll find the best mortgage for you from 20+ bank and non-bank lenders
      </div>
      <Slider {...settings}>
        <LogoContainer>
          <Image height={40} width={80} src={Anz} alt="ANZ" objectFit="contain" />
        </LogoContainer>
        <LogoContainer>
          <Image height={40} width={70} src={Bnz} alt="BNZ" objectFit="contain" />
        </LogoContainer>
        <LogoContainer>
          <Image height={40} width={70} src={Asb} alt="ASB" objectFit="contain" />
        </LogoContainer>
        <LogoContainer>
          <Image height={40} width={70} src={Kiwibank} alt="Kiwibank" objectFit="contain" />
        </LogoContainer>
        <LogoContainer>
          <Image height={40} width={66} src={CoOpBank} alt="Co-operative Bank" objectFit="contain" />
        </LogoContainer>
        <LogoContainer>
          <Image height={40} width={54} src={Sbs} alt="SBS" objectFit="contain" />
        </LogoContainer>
        <LogoContainer>
          <Image height={40} width={62} src={Westpac} alt="Westpac" objectFit="contain" className="h-10" />
        </LogoContainer>
      </Slider>
    </div>
  )
}
export default BankLogosCarousel

const LogoContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <div className="flex scale-90 items-center justify-center py-3 sm:scale-100">{children}</div>
    </div>
  )
}
