import ButtonSchemaToButtonComponent from 'lib/switch-fns/button-schema-to-button-component'
import { stringToImgComponent } from 'lib/switch-fns/string-to-img-component'
import { Button, TextWithImage } from 'types/strapi/item'

type Props = {
  textWithImage: TextWithImage
  button: Button
  switchContentPlacement: boolean
}

const TextWithImageButton = ({ textWithImage, button, switchContentPlacement }: Props) => {
  return (
    <div className={`mx-auto flex w-10/12 flex-col md:flex-row ${switchContentPlacement ? 'md:flex-row-reverse' : ''}`}>
      <div className="my-auto grid w-full items-center gap-3 md:w-1/2">
        <h3 className="font-semibold">{textWithImage?.title}</h3>
        <p>{textWithImage?.intro}</p>
        {button?.title && ButtonSchemaToButtonComponent(button)}
      </div>
      <div className="mx-auto w-3/4 sm:scale-110 md:w-1/2">{stringToImgComponent(textWithImage?.image)}</div>
    </div>
  )
}

export default TextWithImageButton
