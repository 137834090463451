import { SVGProps } from 'react'

const ImgCatSleeping = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 184 126" {...props}>
      <path
        d="M175,57c-.459,15.124,1.04,19.792-5.653,33.618-2.409,4.978-5.243,9.958-9.241,13.771C116.858,145.629,68.533,61.534,33,90.05"
        transform="translate(0 2)"
        fill="none"
        stroke="#4038b2"
        strokeLinecap="round"
        strokeWidth="17"
        fillRule="evenodd"
      />
      <path
        d="M15.056,15.326q-28.148,30.6-3.672,51.407s-8.567-1.224-8.567,4.9q0,6.12,17.134,3.672h164q1.224-55.079-25.7-68.543C131.321-6.706,105.621,3.086,95.83,9.206s-22.029,23.256-30.6,25.7S53,37.357,44.428,32.461Q41.981,30.013,43.2,15.326,32.19,22.67,30.966,28.789t-15.91,0Z"
        fill="#4038b2"
        fillRule="evenodd"
      />
      <path d="M6,48c3.163,7.113,8.037,8.646,11,.517" fill="none" stroke="#ef426f" strokeWidth="1" fillRule="evenodd" />
      <path
        d="M27,48.654C31.054,55.4,37.617,56.383,40,48"
        fill="none"
        stroke="#ef426f"
        strokeWidth="1"
        fillRule="evenodd"
      />
      <path
        d="M21.158,57c-.971,1.406-2.523,2.037-2.081,2.5.611.634,3.562.709,3.893,0,.246-.527-1.077-.912-1.989-2.206"
        fill="#ef426f"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default ImgCatSleeping
